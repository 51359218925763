@import '../../styles/new/variables';
@import '../../styles/new/mixins';

.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.headerBtns {
  align-items: center;
  display: flex;
}

.error {
  color: $warning;
}

.status {
  font-size: 2.4rem;
}

.qboBtn {
  margin: 0 0 0 1rem;
}

.boldBtn {
  font-weight: bold;
}

.editInvoiceBtn {
  margin-right: 2rem;
}

.details {
  margin-top: 2rem;
}

.oldLink {
  font-style: italic;
  margin: 0 1rem 0 0;
}

.detailTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

.qboConfirmationBtn {
  font-weight: 500;
}

.detail {
  font-size: 16px;
}

.detailMB {
  margin-bottom: 1.25rem;
}

.invoiceButtons {
  display: flex;
  flex-direction: row;
}

.voidInvoiceModalContainer {
  width: 40%;
  position: relative;
}

.voidInvoiceModal {
  background-color: $white;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .7);
  color: $dark-gray;
  font-size: 1.4rem;
  padding: 2rem;
  width: 100%;
  min-height: 30vh;
}

.closeModalBtn {
  position: relative;
  display: block !important;
  font-size: 16px;
  top: -10px;
}

.closeModalIcon {
  font-size: 18px;
  position: relative;
  top: 4px;
}

.cancelBtn {
  font-size: 16px;
  margin-right: 1rem;
}

.voidInvoiceContainer {
  padding: 2rem;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.voidInvoiceButtonWrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.voidInvoiceText {
  font-size: 1.6rem;
  text-align: center;
}
.voidInvoicesFooter {
  margin-top: 3rem;
  font-style: italic;
}

@include respond-to('lg') {
  .detailsRow {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    align-items: flex-start;
  }

  .detailsRow > div {
    &:first-child {
      margin-right: 3rem;
      flex-basis: 25%;
    }
    &:last-child {
      flex-grow: 1;
    }
  }
}
